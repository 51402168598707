<template>
  <div class="projectManage">
    <ds-header title="项目管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="项目名称：" :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }">
        <a-input v-model="searchForm.project_name" placeholder="请输入项目名称" />
      </a-form-item>
      <a-form-item label="项目地址：" :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }">
        <a-input v-model="searchForm.project_address" placeholder="请输入项目地址" />
      </a-form-item>
      <a-form-item label="透出渠道：">
        <a-select
          v-model="searchForm.penetrate"
          style="width: 200px"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in options" :key="option.id" :value="option.id">
            {{ option.value }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <SearchResetModule @search="search" @resetSearchForm="resetSearchForm"></SearchResetModule>
      </a-form-item>
    </a-form>
    <div style="padding-top: 15px" v-if="$checkButtonPermissions($buttonPermissionsConfig.baseSet.projectManage.add)">
      <ds-button :type="$DSBT.DS_ADD" title="新增项目" @click="addProject" />
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => record.id"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        tableLayout="fixed"
        @change="pageChange"
        bordered
      >
        <span slot="action" slot-scope="text, record" class="action">
          <ActionModule :actions="activeOperation(record)" @action="action" :data="text"></ActionModule>
        </span>
      </a-table>
    </div>
    <ConfirmBoxModule ref="confirmBoxModule"></ConfirmBoxModule>
  </div>
</template>
<script>
import * as api from "@/api/baseSet/projectManage";
import { REQUEST_CODE } from "@/constant/status";
import { mapState } from "vuex";
import Area from "@/utils/area";
import { isPlatformAccount } from "@/config";
import PenetrateStatus from "./utils/penetrateUtil";
import PagingConfig from "@/utils/pagingConfig";
import ActionConfig from "@/utils/actionConfig";
import ActionModule from "@/components/actionModule";
import ConfirmBoxModule from "@/components/confirmBoxModule";
import ConfirmConfig from "@/utils/confirmConfig";
import SearchResetModule from "@/components/searchResetModule";

const initSearchForm = {
  //项目名称
  project_name: "",
  //项目地址
  project_address: "",
  //透出渠道
  penetrate: undefined,
  //租户id
  tenant_id: "",
};

export default {
  name: "ProjectManage",
  components: {
    ActionModule,
    ConfirmBoxModule,
    SearchResetModule,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
    }),
  },
  data() {
    return {
      //判断是否是平台账号
      isPlatformAccount: isPlatformAccount,
      //搜索数据
      searchForm: { ...initSearchForm },
      //透出渠道选项
      options: [
        {
          id: "3",
          value: "全部",
        },
        {
          id: "1",
          value: "APP",
        },
        {
          id: "2",
          value: "微信小程序",
        },
      ],
      //展示数据
      table: {
        //数据
        dataInfo: [],
        //title数据
        columns: [
          {
            title: "项目名称",
            dataIndex: "project_name",
            key: "project_name",
          },
          {
            title: "项目位置",
            dataIndex: "address",
            key: "address",
          },
          {
            title: "所在城市",
            dataIndex: "project_area_name",
            key: "project_area_name",
            customRender: (text) => {
              const regionArr = text.split("/")[0].split("-");
              const cityArr = this.getCityList(regionArr[0]);
              let city = "";
              cityArr.forEach((item) => {
                if (item.code === regionArr[1]) {
                  city = item.name;
                }
              });
              return city;
            },
            width: "200px",
          },
          {
            title: '项目状态',
            dataIndex: 'project_status',
            customRender: (text) => {
              return text === 0 ? '未建' : text === 1 ? '在建' : text === 2 ? '完工' : text === 3 ? '投产' : text === 4 ? '停业' : '-'
            }
          },
          {
            title: "透出渠道",
            dataIndex: "penetrate",
            key: "penetrate",
            customRender: (text) => {
              if (text === PenetrateStatus.PENETRATE_ALL) {
                return "APP, 微信小程序";
              } else if (text === PenetrateStatus.PENETRATE_APP) {
                return "APP";
              } else if (text === PenetrateStatus.PENETRATE_APPLET) {
                return "微信小程序";
              }
              return "";
            },
            width: "150px",
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: PagingConfig.defaultPage,
          pageSize: PagingConfig.defaultPageSize,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      //操作集合
      actions: [
        {
          type: ActionConfig.addChild.ACTION_TYPE,
          title: ActionConfig.addChild.ACTION_TITLE,
          permissions: this.$buttonPermissionsConfig.baseSet.projectManage.addChild
        },
        {
          type: ActionConfig.look.ACTION_TYPE,
          title: ActionConfig.look.ACTION_TITLE,
          permissions: this.$buttonPermissionsConfig.baseSet.projectManage.look
        },
        {
          type: ActionConfig.edit.ACTION_TYPE,
          title: ActionConfig.edit.ACTION_TITLE,
          permissions: this.$buttonPermissionsConfig.baseSet.projectManage.edit
        },
        {
          type: ActionConfig.delete.ACTION_TYPE,
          title: ActionConfig.delete.ACTION_TITLE,
          permissions: this.$buttonPermissionsConfig.baseSet.projectManage.delete
        },
      ],
    };
  },
  methods: {
    // 动态插入操作
    activeOperation(row) {
      if(row.child_type === 0) {
        return this.actions
      } else {
        return this.actions.slice(1, 4)
      }
    },
    //查询
    search() {
      this.refreshData();
    },
    //重置
    resetSearchForm() {
      this.searchForm = {
        ...initSearchForm,
      };
      this.refreshData();
    },
    //添加项目
    addProject() {
      this.skipAddProject("0")
    },
    addChildProject(data) {
      const currentProjectId = data.id
      this.$router.push({
        path: "/baseSet/projectManage/addProject",
        query: {
          parentId: currentProjectId,
          type: 'addChild',
          showType: '0'
        }
      })
    },
    //查看
    look(text) {
      this.skipAddProject("2", text.id)
    },
    //编辑
    edit(text) {
      this.skipAddProject("1", text.id)
    },
    // 跳转添加项目路由
    skipAddProject(showType, id) {
      let query = {showType};
      if (id) {
        query.id = id;
      }
      this.$router.push({
        path: "/baseSet/projectManage/addProject",
        query
      })
    },
    //删除
    async remove(text) {
      const confirmData = {
        title: "是否确认删除该项目?",
        content: "删除后该项目及其对应数据将同步删除，请谨慎操作",
        type: ConfirmConfig.CONFIRM_TYPE_WARNING,
      };
      await this.$refs.confirmBoxModule.show(confirmData);
      this.deleteProject(text.id);
    },
    //列表操作
    action(actionType, data) {
      if (actionType === ActionConfig.look.ACTION_TYPE) {
        this.look(data);
      } else if (actionType === ActionConfig.edit.ACTION_TYPE) {
        this.edit(data);
      } else if (actionType === ActionConfig.delete.ACTION_TYPE) {
        this.remove(data);
      } else if(actionType === ActionConfig.addChild.ACTION_TYPE) {
        this.addChildProject(data)
      }
    },
    //分页操作
    pageChange(option) {
      this.refreshData(option.current, option.pageSize);
    },

    /**网络请求 */
    //获取项目列表
    async getProjectList() {
      try {
        const result = await api.getProjectList(
          this.getListDataTidy(),
          this.table.pagination.current,
          this.table.pagination.pageSize
        );
        const { data: { projects } } = result
        if (result.code === REQUEST_CODE) {
          let tableData = []
          projects && projects.length && projects.forEach(item => {
            if(item.child.length === 0) {
              delete item.child
            } else {
              item['children'] = item.child
            }
            tableData.push(item)
          });
          this.table.dataInfo = tableData
          this.table.pagination.total = result.data.count;
        } else {
          this.$message.error("获取项目列表失败");
        }
      } catch (error) {}
    },
    //删除项目
    async deleteProject(id) {
      try {
        await api.deleteProject(id);
        this.$message.success("删除成功");
        this.refreshData();
      } catch (error) {
        this.$message.error("删除失败");
      }
    },
    //获取市数据
    getCityList(code) {
      return Area.getCityOrDistrictList(code);
    },
    //刷新数据
    refreshData(current = PagingConfig.defaultPage, pageSize = PagingConfig.defaultPageSize) {
      if (pageSize !== this.table.pagination.pageSize) {
        this.table.pagination.current = PagingConfig.defaultPage;
      } else {
        this.table.pagination.current = current;
      }
      this.table.pagination.pageSize = pageSize;
      this.getProjectList();
    },

    /**数据处理 */
    //获取列表数据处理
    getListDataTidy() {
      let penetrate = undefined;
      if (this.searchForm.penetrate === PenetrateStatus.PENETRATE_ALL || !this.searchForm.penetrate) {
        penetrate = "";
      } else {
        penetrate = this.searchForm.penetrate;
      }
      return {
        ...this.searchForm,
        penetrate,
      };
    },
  },
  created() {
    this.refreshData();
    if (this.isPlatformAccount) {
      this.table.columns.splice(3, 0, {
        title: "所属租户",
        dataIndex: "tenant_name",
        key: "tenant_name",
      });
    }
    this.$checkTableActionIsShow(this.actions) && this.table.columns.push(this.$backTableAction());
  },
};
</script>

<style lang="scss" scoped>
</style>
